<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <h1>{{ $t("buyProducts") }}</h1>
              <div class="search-results"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />
    <!-- Prodcuts -->
    <section v-if="initProducts.length > 0" class="list-view">
      <b-card
        v-for="(product, index) in initProducts"
        :key="index"
        class="ecommerce-card"
        no-body
      >
        <div
          class="item-img text-left p-1 d-flex justify-content-between border-bottom"
        >
          <b-link
            :to="{
              name: 'apps-e-commerce-product-details',
              params: { slug: product.slug },
            }"
          >
            <b-img
              :src="require('@/assets/images/logo/logo.png')"
              :alt="`${product.name}`"
              style="width: 150px"
            />
          </b-link>
          <div>
            <b-badge variant="primary">
              <span class="h5 text-white font-weight-bold">
                {{ index % 2 === 0 ? $t("monthly") : $t("yearly") }}
              </span></b-badge
            >
          </div>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body h2"
              :to="{
                name: 'apps-e-commerce-product-details',
                params: { slug: product.slug },
              }"
            >
              {{ product.saleitemname }}
            </b-link>
            <b-card-text class="item-company">
              <b-link class="ml-25"> </b-link>
            </b-card-text>
          </h6>
          <div class="item-wrapper">
            <div class="item-rating"></div>
            <div>
              <h6 class="item-price">
                ₺{{
                  product[index % 2 == 0 ? "pricepermonth" : "priceperyear"]
                }}
              </h6>
            </div>
          </div>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <div
              v-for="(extraPackage, eIndex) in product.extraPackages"
              :key="eIndex"
              class="flex-1"
            >
              <div>
                {{ extraPackage.saleitemname }}
                <small class="font-weight-bold text-success">
                  ₺{{
                    extraPackage[
                      index % 2 == 0 ? "pricepermonth" : "priceperyear"
                    ]
                  }}</small
                >
              </div>
              <b-form-spinbutton
                min="0"
                max="20"
                size="sm"
                :value="extraPackage.quantity"
                @input="
                  (val) => {
                    product.extraPackages[eIndex].quantity = val;
                    calcTotalPrice(product, index);
                  }
                "
              />
            </div>
          </div>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center p-3">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ₺{{
                  product.totalPrice == 0
                    ? product[index % 2 == 0 ? "pricepermonth" : "priceperyear"]
                    : product.totalPrice
                }}
              </h4>
            </div>
          </div>

          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="buyProduct(product, index % 2 == 0 ? 2 : 3)"
          >
            <feather-icon icon="ShoppingCartIcon" class="mr-50" />
            <span>{{
              product.isInCart ? "View In Cart" : $t("addToCart")
            }}</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BBadge,
  BFormSpinbutton,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useShop";
import { useEcommerceUi } from "./useCart";

import store from "../../store";
import { getUserData } from "@/auth/utils";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV

    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BFormSpinbutton,
    BBadge,
    // SFC
  },
  setup() {
    const { filters, filterOptions, sortBy, sortByOptions } =
      useShopFiltersSortingAndPagination();

    const { handleCartActionClick } = useEcommerceUi();

    const { itemView, itemViewOptions, totalProducts } = useShopUi();

    const { initProducts, fetchInitProducts } = useShopRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    // Wrapper Function for `fetchInitProducts` which can be triggered initially and upon changes of filters
    const fetchinitProducts = async () => {
      await fetchInitProducts().then((response) => {
        initProducts.value = response;

        totalProducts.value = response.length;
      });
    };

    fetchinitProducts();

    const calcTotalPrice = (product, index) => {
      const baseItemPrice =
        initProducts.value[index][
          index % 2 == 0 ? "pricepermonth" : "priceperyear"
        ];
      const extraPackagePrice = initProducts.value[index].extraPackages
        .filter((x) => x.quantity != 0)
        .reduce((total, extraPackage) => {
          return (
            total +
            extraPackage[index % 2 == 0 ? "pricepermonth" : "priceperyear"] *
              extraPackage.quantity
          );
        }, 0);
      initProducts.value[index].totalPrice = (
        parseFloat(baseItemPrice) + parseFloat(extraPackagePrice)
      ).toFixed(2);
    };
    watch(
      [filters, sortBy],
      () => {
        fetchinitProducts();
      },
      {
        deep: true,
      }
    );

    const buyProduct = async (product, priceType) => {
      const basket = [
        {
          saleitemid: product.id,
          quantity: product.quantity ?? 1,
          pricetype: priceType,
          orjprice:
            priceType == 2 ? product.orjpricepermonth : product.orjpriceperyear,
        },
        ...product.extraPackages
          .filter((x) => x.quantity != 0)
          .map((x) => ({
            saleitemid: x.id,
            quantity: x.quantity,
            pricetype: priceType,
            orjprice: priceType == 2 ? x.orjpricepermonth : x.orjpriceperyear,
          })),
      ];
      await store.dispatch("purchaseModule/saveUserBasket", basket);
      await store.dispatch("purchaseModule/getUserBasket", getUserData().id);
    };

    return {
      buyProduct,
      calcTotalPrice,
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      handleCartActionClick,

      // useShopRemoteData
      initProducts,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
